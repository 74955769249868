var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "info" }, [
    _c("div", { staticClass: "container" }, [
      _c("h3", [_vm._v("利用規約")]),
      _c("p", { staticClass: "my-4" }),
      _c("div", [
        _vm._v(
          "この利用規約（以下、「本規約」といいます。）は、株式会社 ロビンソン（以下、「当社」といいます。）がこのウェブサイト上で提供するサービス（以下、「本サービス」といいます。）の利用条件を定めるものです。"
        )
      ]),
      _c("div", [
        _vm._v(
          "本サービスを利用される皆さま（以下、「ユーザー」 「登録ユーザー」といいます。）には、本規約に従って、本サービスをご利用いただきます。"
        )
      ]),
      _c("p"),
      _c("p", { staticClass: "my-4" }),
      _c("div", { staticClass: "title" }, [
        _vm._v("本規約におけるユーザーの定義")
      ]),
      _c("div", { staticClass: "indent" }, [
        _vm._v("ユーザー：本サービスを利用するすべての利用者様")
      ]),
      _c("div", { staticClass: "indent" }, [
        _vm._v(
          "登録ユーザー：本サービスのユーザー登録(有料プラン登録)を行い、本サービスを利用する利用者様"
        )
      ]),
      _c("p"),
      _c("p", { staticClass: "my-4" }),
      _c("div", { staticClass: "title" }, [_vm._v("第1条（適用）")]),
      _c("div", { staticClass: "section-box" }, [
        _vm._v(
          "1.本規約は、ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。"
        )
      ]),
      _c("div", { staticClass: "section-box" }, [
        _vm._v(
          "2.当社は本サービスに関し、本規約のほか、ご利用にあたってのルール等、各種の定め（以下、「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず、本規約の一部を構成するものとします。"
        )
      ]),
      _c("div", { staticClass: "section-box" }, [
        _vm._v(
          "3.本規約の規定が前条の個別規定の規定と矛盾する場合には、個別規定において特段の定めなき限り、個別規定の規定が優先されるものとします。"
        )
      ]),
      _c("p"),
      _c("p", { staticClass: "my-4" }),
      _c("div", { staticClass: "title" }, [_vm._v("第2条（利用登録）")]),
      _c("div", { staticClass: "section-box" }, [
        _vm._v(
          "1.本サービスにおいては、登録希望者が本規約に同意の上、当社の定める方法によって利用登録を申請し、当社がこれを承認することによって、登録ユーザーの利用登録が完了するものとします。"
        )
      ]),
      _c("div", { staticClass: "section-box" }, [
        _vm._v(
          "2.当社は、利用登録の申請者に以下の事由があると判断した場合、利用登録の申請を承認しないことがあり、その理由については一切の開示義務を負わないものとします。"
        )
      ]),
      _vm._m(0),
      _c("p"),
      _c("p", { staticClass: "my-4" }),
      _c("div", { staticClass: "title" }, [
        _vm._v("第3条（登録ユーザーID及びパスワードの管理）")
      ]),
      _c("div", { staticClass: "section-box" }, [
        _vm._v(
          "1.登録ユーザーは、自己の責任において、本サービスの登録ユーザーID及びパスワードを適切に管理するものとします。"
        )
      ]),
      _c("div", { staticClass: "section-box" }, [
        _vm._v(
          "2.登録ユーザーは、いかなる場合にも、登録ユーザーID及びパスワードを第三者に譲渡又は貸与し、若しくは第三者と共用することはできません。当社は、登録ユーザーIDとパスワードの組み合わせが登録情報と一致してログインされた場合には、その登録ユーザーIDを登録している登録ユーザー自身による利用とみなし、登録ユーザーに対していかなる賠償責任を負わず、補償もいたしません。"
        )
      ]),
      _c("div", { staticClass: "section-box" }, [
        _vm._v(
          "3.登録ユーザーID及びパスワードが第三者によって使用されたことによって生じた損害は、当社に故意又は重大な過失がある場合を除き、当社は一切の責任を負わないものとします。"
        )
      ]),
      _c("div", { staticClass: "section-box" }, [
        _vm._v(
          "4.登録ユーザーは、登録ユーザーID及びパスワードが第三者によって使用されていることが判明した場合には、直ちに当社にその旨を連絡するとともに、当社から指示がある場合にはこれに従うものとします。"
        )
      ]),
      _c("p"),
      _c("p", { staticClass: "my-4" }),
      _c("div", { staticClass: "title" }, [
        _vm._v("第4条（利用料金及び支払方法）")
      ]),
      _c("div", { staticClass: "section-box" }, [
        _vm._v(
          "1.登録ユーザーは、本サービスの有料部分の対価として、当社が別途定め、本ウェブサイトに表示する利用料金を、当社が指定する方法により支払うものとします"
        )
      ]),
      _c("div", { staticClass: "section-box" }, [
        _vm._v(
          "2.登録ユーザーの月額料金をお支払い頂けなかった場合、自動的に無料プランに変更となり、使用できる機能に制限が発生する可能性があります。"
        )
      ]),
      _c("div", { staticClass: "section-box" }, [
        _vm._v(
          "3.支払い済みの利用料金に対しては、当社が定める理由以外を除き返金しません。"
        )
      ]),
      _c("div", { staticClass: "section-box" }, [
        _vm._v(
          "4.料金支払いにおいて、当社からの領収書発行には対応いたしかねます。"
        )
      ]),
      _c("p"),
      _c("p", { staticClass: "my-4" }),
      _c("div", { staticClass: "title" }, [_vm._v("第5条（禁止事項）")]),
      _c("div", { staticClass: "section-box" }, [
        _vm._v(
          "ユーザーは、本サービスの利用にあたり、以下の行為をしてはなりません。"
        )
      ]),
      _vm._m(1),
      _c("p"),
      _c("p", { staticClass: "my-4" }),
      _c("div", { staticClass: "title" }, [
        _vm._v("第6条（本サービスの提供の停止等）")
      ]),
      _c("div", { staticClass: "section-box" }, [
        _vm._v(
          "1.当社は、以下のいずれかの事由があると判断した場合、ユーザーに事前に通知することなく本サービスの全部又は一部の提供を停止又は中断することができるものとします。"
        )
      ]),
      _vm._m(2),
      _c("div", { staticClass: "section-box" }, [
        _vm._v(
          "2.当社は、本サービスの提供の停止又は中断により、ユーザー又は第三者が被ったいかなる不利益又は損害についても、一切の責任を負わないものとします。"
        )
      ]),
      _c("p"),
      _c("p", { staticClass: "my-4" }),
      _c("div", { staticClass: "title" }, [
        _vm._v("第7条（利用制限及び登録抹消）")
      ]),
      _c("div", { staticClass: "section-box" }, [
        _vm._v(
          "1.当社は、ユーザーが以下のいずれかに該当する場合には、事前の通知なく、ユーザーに対して、本サービスの全部若しくは一部の利用を制限し、又はユーザーとしての登録を抹消することができるものとします。"
        )
      ]),
      _vm._m(3),
      _c("div", { staticClass: "section-box" }, [
        _vm._v(
          "2. 当社は、本条に基づき当社が行った行為によりユーザーに生じた損害について、一切の責任を負いません。"
        )
      ]),
      _c("p"),
      _c("p", { staticClass: "my-4" }),
      _c("div", { staticClass: "title" }, [_vm._v("第8条（退会）")]),
      _c("div", [
        _vm._v(
          "登録ユーザーは、当社の定める退会手続により、本サービスから退会できるものとします。なお、登録ユーザーの地位は一身専属のものとし、当社が当該登録ユーザーの死亡を知り得た時点をもって、当該登録ユーザーの退会手続が履行されたものとみなします。"
        )
      ]),
      _c("div", [
        _vm._v(
          "登録ユーザーの期間に作成したデータ等の登録内容は、当社の裁量により削除されます。"
        )
      ]),
      _c("p"),
      _c("p", { staticClass: "my-4" }),
      _c("div", { staticClass: "title" }, [
        _vm._v("第9条（サービス内容の変更等）")
      ]),
      _c("div", [
        _vm._v(
          "当社は、ユーザーに通知することなく、本サービスの内容を変更又は本サービスの提供を中止することができるものとし、これによってユーザーに生じた損害について一切の責任を負いません。"
        )
      ]),
      _c("p"),
      _c("p", { staticClass: "my-4" }),
      _c("div", { staticClass: "title" }, [_vm._v("第10条（利用規約の変更）")]),
      _c("div", [
        _vm._v(
          "1.当社は、必要と判断した場合には、ユーザーに通知することなくいつでも本規約を変更することができるものとします。なお、本規約の変更後、本サービスの利用を開始した場合には、当該ユーザーは変更後の規約に同意したものとみなします。"
        )
      ]),
      _c("div", [
        _vm._v(
          "2.前項による変更後の本規約は、当社が別途定める場合を除き、当社が運営するＷｅｂサイト等において表示された時点より、効力を生じるものとします。"
        )
      ]),
      _c("p"),
      _c("p", { staticClass: "my-4" }),
      _c("div", { staticClass: "title" }, [
        _vm._v("第11条（個人情報の取扱い）")
      ]),
      _c(
        "div",
        [
          _vm._v(
            "当社は、本サービスの利用によって取得する個人情報については、当社「"
          ),
          _c("router-link", { attrs: { to: "/info/privacy" } }, [
            _vm._v("プライバシーポリシー")
          ]),
          _vm._v("」に従い適切に取り扱うものとします。")
        ],
        1
      ),
      _c("p"),
      _c("p", { staticClass: "my-4" }),
      _c("div", { staticClass: "title" }, [_vm._v("第12条（通知又は連絡）")]),
      _c("div", [
        _vm._v(
          "ユーザーと当社との間の通知又は連絡は、当社の定める方法によって行うものとします。当社は、ユーザーから、当社が別途定める方式に従った変更届け出がない限り、現在登録されている連絡先が有効なものとみなして当該連絡先へ通知又は連絡を行い、これらは、発信時にユーザーへ到達したものとみなします。"
        )
      ]),
      _c("p"),
      _c("p", { staticClass: "my-4" }),
      _c("div", { staticClass: "title" }, [
        _vm._v("第13条（権利義務の譲渡の禁止）")
      ]),
      _c("div", [
        _vm._v(
          "ユーザーは、当社の書面による事前の承諾なく、利用契約上の地位又は本規約に基づく権利若しくは義務を第三者に譲渡し、又は担保に供することはできません。"
        )
      ]),
      _c("p"),
      _c("p", { staticClass: "my-4" }),
      _c("div", { staticClass: "title" }, [
        _vm._v("第14条（準拠法・裁判管轄）")
      ]),
      _c("div", { staticClass: "section-box" }, [
        _vm._v("1.本規約の解釈にあたっては、日本法を準拠法とします。")
      ]),
      _c("div", { staticClass: "section-box" }, [
        _vm._v(
          "2.本サービスに関して紛争が生じた場合には、東京地方裁判所を第一審の専属的合意管轄裁判所とします。"
        )
      ]),
      _c("p"),
      _c("p", { staticClass: "my-4" }),
      _c("div", [_vm._v("制定：2020年5月31日")]),
      _c("div", { staticClass: "text-right" }, [_vm._v("以上")]),
      _c("p")
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "indent" }, [
      _c("div", [_vm._v("1）利用登録の申請に際して虚偽の事項を届け出た場合")]),
      _c("div", [
        _vm._v("2）本規約に違反したことがある者からの申請である場合")
      ]),
      _c("div", [_vm._v("3）その他、当社が利用登録を相当でないと判断した場合")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "indent" }, [
      _c("div", [_vm._v("1.法令又は公序良俗に違反する行為")]),
      _c("div", [_vm._v("2.犯罪行為に関連する行為")]),
      _c("div", [
        _vm._v(
          "3.本サービスの内容等、本サービスに含まれる著作権、商標権ほか知的財産権を侵害する行為"
        )
      ]),
      _c("div", [
        _vm._v(
          "4.当社、ほかのユーザー、又はその他第三者のサーバー又はネットワークの機能を破壊したり、妨害したりする行為"
        )
      ]),
      _c("div", [
        _vm._v("5.本サービスによって得られた情報を商業的に利用する行為")
      ]),
      _c("div", [_vm._v("6.当社のサービスの運営を妨害するおそれのある行為")]),
      _c("div", [_vm._v("7.不正アクセスをし、又はこれを試みる行為")]),
      _c("div", [
        _vm._v("8.他のユーザーに関する個人情報等を収集又は蓄積する行為")
      ]),
      _c("div", [_vm._v("9.不正な目的を持って本サービスを利用する行為")]),
      _c("div", [
        _vm._v(
          "10.他のユーザーその他第三者を誹謗中傷、名誉を毀損し、プライバシーを侵害する行為"
        )
      ]),
      _c("div", [_vm._v("11.選挙運動又はこれに類似する行為")]),
      _c("div", [
        _vm._v(
          "12.本サービスの他のユーザー又はその他の第三者に不利益、損害、不快感を与える行為"
        )
      ]),
      _c("div", [_vm._v("13.他のユーザーに成りすます行為")]),
      _c("div", [
        _vm._v(
          "14.当社が許諾しない本サービス上での宣伝、広告、勧誘、又は営業行為"
        )
      ]),
      _c("div", [_vm._v("15.面識のない異性との出会いを目的とした行為")]),
      _c("div", [
        _vm._v(
          "16.当社のサービスに関連して、反社会的勢力に対して直接又は間接に利益を供与する行為"
        )
      ]),
      _c("div", [_vm._v("17.その他、当社が不適切と判断する行為")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "indent" }, [
      _c("div", [
        _vm._v(
          "1）本サービスにかかるコンピュータシステムの保守点検又は更新を行う場合"
        )
      ]),
      _c("div", [
        _vm._v(
          "2）地震、落雷、火災、停電又は天災などの不可抗力により、本サービスの提供が困難となった場合"
        )
      ]),
      _c("div", [
        _vm._v("3）コンピュータ又は通信回線等が事故により停止した場合")
      ]),
      _c("div", [
        _vm._v("4）その他、当社が本サービスの提供が困難と判断した場合")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "indent" }, [
      _c("div", [_vm._v("1）本規約のいずれかの条項に違反した場合")]),
      _c("div", [_vm._v("2）登録事項に虚偽の事実があることが判明した場合")]),
      _c("div", [_vm._v("3）料金等の支払債務の不履行があった場合")]),
      _c("div", [_vm._v("4）当社からの連絡に対し、一定期間返答がない場合")]),
      _c("div", [
        _vm._v("5）本サービスについて、最終の利用から一定期間利用がない場合")
      ]),
      _c("div", [
        _vm._v("6）その他、当社が本サービスの利用を適当でないと判断した場合")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }